import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CustomerSuccess = () => {
  return (
    <Layout variant="case-study">
      <SEO title="20+ integrations on Tactic" description="" />
      <div className="container mx-auto my-12 p-6">
        <div className="text-center max-w-5xl mx-auto">
          <p className="text-lilac mb-2">Customer Success</p>
          <h1 className="text-3xl md:text-5xl font-bold my-4 text-white">
            Nail targeting the first time
          </h1>
          <p className="text-dark-grey text-sm mb-2 max-w-5xl">
            Whether you are working on ideal customer profiles and target
            accounts for the first time, or wrestling with a complex data
            problem and plugging data skills gaps, time to value is critical.
            Partner with Tactic's world class customer success to ensure impact.
          </p>
        </div>
        <section className="max-w-5xl mx-auto my-24">
          <div className="border border-white/10 rounded-lg w-full text-white p-12 flex flex-col lg:flex-row gap-2 my-2 bg-white/5">
            <h3 className="text-3xl w-5/12">Success strategy</h3>
            <p className="w-7/12">
              <ul className="list-disc">
                <li>Optimise deal size, conversion rates</li>
                <li>Strategic planning and kick off workshops</li>
                <li>Get a detailed action plan to deliver impact</li>
                <li>Setup quarterly business reviews</li>
              </ul>
            </p>
          </div>
          <div className="border border-white/10 rounded-lg w-full text-white p-12 flex flex-col lg:flex-row gap-2 my-2 bg-white/5">
            <h3 className="text-3xl w-5/12">Technical consulting</h3>
            <ul className="list-disc">
              <li>Customise Tactic and integrate with your marketing and sales stack</li>
              <li>Drive adoption with on demand webinars and workshops</li>
              <li>Actionable recommendations to improve targeting and personalisation</li>
            </ul>
          </div>
          <div className="border border-white/10 rounded-lg w-full text-white p-12 flex flex-col lg:flex-row gap-2 my-2 bg-white/5">
            <h3 className="text-3xl w-5/12">Managed Services</h3>
            <ul className="list-disc">
              <li>Extend your revenue operations and sales operations team</li>
              <li>Gain new data expertise and capacity</li>
              <li>Instant access via Zoom, phone calls and Slack</li>
              <li>Just like a part of your organisation</li>
            </ul>
          </div>
          <div className="border border-white/10 rounded-lg w-full text-white p-12 flex flex-col lg:flex-row gap-2 my-2 bg-white/5">
            <h3 className="text-3xl w-5/12">Training</h3>
            <ul className="list-disc">
              <li>Targeting training tailored to your ideal customer profile</li>
              <li>State of the art, multi-channel personalisation training</li>
            </ul>
          </div>
          <div className="border border-white/10 rounded-lg w-full text-white p-12 flex flex-col lg:flex-row gap-2 my-2 bg-white/5">
            <h3 className="text-3xl w-5/12">Data Services</h3>
            <ul className="list-disc">
              <li>CRM deduplication</li>
              <li>CRM data cleaning</li>
              <li>Sourcing hard to find data</li>
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default CustomerSuccess
